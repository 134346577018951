.container{
  width:auto;
  height:auto;
  display:flex;
  flex-direction:raw;
}
.flexboxA{
  display: flex;
  flex-direction: column;
}
.flexboxB{
  display: flex;
  flex-direction: row;
}
.wrap{
  width:40%;
}
.wrap2{
  width:40%;
}
.wrap2{
  width:40%;
}
.navbar {
  background-color: rgb(212, 101, 101);
  display: flex;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  justify-content:space-evenly;
  width: 100%;

}
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
}
.wrapper {
  width: 100%;
}
.img-me {
    outline: 1px solid black;
  border-radius: 22%;
  width: 75%;
  text-align: center;
}
.img-info {
  width: 100%;
}
.img-info h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 60px;
  margin-top: 130px;
}

@media only screen and (max-width: 300px) {
  body {
    font-size: 10px;
  }
}


body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
}
button {
  padding: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

h3, h4 {
  margin-bottom: 0;
}

nav {
  border-bottom: 4px solid black;
  text-align: center;
  position: fixed;
  top: 0;
  max-width: 100%;
  background: white;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 16px 32px;
}

nav a:hover {
  background: black;
  color: white;
}

#page-body {
  max-width: 1200px;
  margin: auto;
  padding-top: 24px;
}

.article-list-item {
  text-decoration: none;
  color: black;
  padding: 16px 0 0 0;
}

.article-list-item p {
  padding-bottom: 32px;
  border-bottom: 2px solid black;
}

#add-comment-form {
  border-bottom: 2px solid black;
  padding: 16px;
}

#add-comment-form h3 {
  margin-bottom: 16px;
}

#add-comment-form input, textarea {
  display: block;
  max-width: 300px;
  padding: 8px;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  border: 2px solid black;
  margin-bottom: 16px;
}

#add-comment-form button {
  max-width: 320px;
}

#upvotes-section button, p {
  display: inline-block;
  margin-right: 16px;
}

.comment {
  border-bottom: 2px solid black;
  padding: 16px;
}

.font-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  margin-top: -30px;
}

.font-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 60px;
  margin-top: 130px;
}

#parent {
  display: flex;
}
#narrow {
  max-width: 150px;
  display: inline-block;
  margin-top: 17px;

}
#narrowtextleft {
  max-width: auto;
  display: inline-block;
  margin-top: 17px;
  font-family: 'Open Sans', sans-serif;
  margin-left: 4%;

}
#narrowtextright {
  max-width: auto;
  display: inline-block;
  margin-top: 17px;
  font-family: 'Open Sans', sans-serif;
  margin-right: 5%;
}
#wide {
  flex: 5;
  display: inline-block;
}
.thick {
  font-weight: bold;
}

.about-list-item {
  padding-bottom: 32px;
  border-bottom: 2px solid black;
}
.dronecontainer {
  width: 100%;
  text-align: center;
}
.outline2 {
  outline: 1px solid black;
  border-radius: 50%;
  width: 25%;
  text-align: center;
}

.contactcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.contactcontainer2 {
  margin-bottom: 30px;
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.sections {
  padding: 4%;
}

.logo-mobile{
  display: none;
}

@media screen and (max-width: 600px) {
  .logo-mobile {
      display: inline-block;
      text-align: center;
  }
}

@media all and (min-width: 920px) {
  .deskContent {display:block;}
  .phoneContent {display:none;}

}

@media all and (max-width: 920px) {
  .deskContent {display:none;}
  .phoneContent {display:block;}

}

